import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import Button from '../button/Button'
import Modal from '../modal/Modal'
import focusSVG from '../../assets/icons/content-focus.svg'
import { occupationsObject } from '../../utils/dicts'
import previewSVG from '../../assets/icons/preview-eye.svg'
import eventMobileSVG from '../../assets/icons/mobile-event-icon.svg'
import heartMobileSVG from '../../assets/icons/mobile-heart-icon.svg'
import reportMobileSVG from '../../assets/icons/mobile-report-icon.svg'
import messageMobileSVG from '../../assets/icons/mobile-message-icon.svg'
import financeMobileSVG from '../../assets/icons/mobile-finance-icon.svg'
import socioemotionalSVG from '../../assets/icons/mini-socioemotional.svg'
import mobileAlertSVG from '../../assets/icons/mobile-alert-icon.svg'
import StyledSVG from '../shared/StyledSVG'
import FormProgress from '../shared/FormProgress'
import MobilePreview from '../shared/MobilePreview'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12
    },
    '& path': {
      stroke: '#4D5E80'
    },
    '& h1': {
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16,
      maxWidth: '25%'
    }
  },
  bottomBtns: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  steps: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& p': {
      margin: 0,
      fontWeight: 700,
      fontSize: 16
    },
    '& span': {
      fontWeight: 500,
      fontSize: 14
    }
  },
  contents: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 20
  },
  focus__label: {
    '& circle': {
      stroke: '#868E96'
    },
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    '& h1': {
      color: '#555555',
      fontSize: 16,
      fontWeight: 700
    }
  },
  mobile: {
    position: 'relative',
    height: '100%',
    backgroundColor: '#F1F3F4',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& .selected': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 50,
      height: 50,
      borderRadius: 50,
      backgroundColor: '#386093'
    }
  },
  mobile__header: {
    '& .padding': {
      height: 30,
      backgroundColor: '#386093',
      width: '100%'
    }
  },
  mobile__main: {
    '& .imgSection': {
      position: 'relative'
    }
  },
  floatBtn: {
    position: 'absolute',
    bottom: -35,
    right: 8,
    height: 50,
    width: 50,
    borderRadius: 50,
    backgroundColor: '#EF7C8F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentInfo: {
    width: 'fit-content',
    marginTop: 5,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& p, span': {
      margin: 0
    },
    '& span': {
      color: '#9B9B9B',
      fontSize: 11,
      fontWeight: 500
    },
    '& p': {
      fontWeight: 600,
      fontSize: 11,
      color: '#555555'
    }
  },
  content: {
    marginTop: 30,
    padding: 3,
    '& .abstract': {
      margin: '0px 0px 20px 0px',
      fontSize: 16,
      fontWeight: 700,
      color: '#7B7B7B'
    },
    '& .text_content': {
      margin: 0,
      fontSize: 14,
      color: '#7B7B7B',
      // color: '#555555',
      fontWeight: 500
    }
  },
  mobile__footer: {
    position: 'sticky',
    bottom: 0,
    boxShadow: '4px 4px 17px 2px #E7E7E7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#FFF',
    height: 70,
    minHeight: 70,
    width: '100%',
    borderRadius: '32px 32px 0px 0px'
  },
  mobile__headbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title': {
      margin: 0,
      fontSize: 20,
      fontWeight: 600,
      color: '#386093'
    }
  }
}))

const ThirdStepThemeForm = props => {
  const {
    history,
    currentStep,
    steps,
    currentTheme,
    contents,
    themeOccupations,
    editThemeRSPA
  } = props
  const classes = useStyles()
  const [confirmModal, setConfirmModal] = React.useState({ open: false })

  return (
    <div>
      <div className={classes.header}>
        <div className='title'>
          <StyledSVG src={previewSVG} height={32} width={32} />
          <h1>Visualize o conteúdo do tema</h1>
        </div>
        <span>
          Abaixo você consegue visualizar o tema e o conteúdo aplicado.
        </span>
      </div>

      <div className={classes.steps}>
        <div className={classes.focus__label}>
          <StyledSVG src={focusSVG} height={32} width={32} />
          <h1>
            Foco(s):{' '}
            <span>
              {themeOccupations
                .map(occupation => {
                  return occupationsObject[occupation]
                })
                .join(', ')}
            </span>
          </h1>
        </div>
        <FormProgress currentStep={currentStep} steps={steps} />
      </div>

      <div className={classes.contents}>
        {contents?.map((content, index) => {
          return (
            <MobilePreview key={index}>
              <div className={classes.mobile}>
                <div>
                  <div className={classes.mobile__header}>
                    <div className='padding' />
                    <div className={classes.mobile__headbar}>
                      <MenuIcon />
                      <p className={'title'}>
                        {content.theme.theme_module.title}
                      </p>
                      <div>
                        <StyledSVG
                          src={mobileAlertSVG}
                          width={20}
                          height={20}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.mobile__main}>
                    <div
                      className='imgSection'
                      style={{
                        backgroundColor:
                          content.theme.theme_module.color || 'transparent'
                      }}
                    >
                      <img
                        src={content.theme.theme_module.cover.image_url}
                        width={'100%'}
                        height={'190px'}
                      />
                      <div className={classes.floatBtn}>
                        <StyledSVG
                          src={heartMobileSVG}
                          width={30}
                          height={30}
                        />
                      </div>
                    </div>
                    <div className={classes.contentInfo}>
                      <span>Data de criação</span>
                      <p>{moment(content.created_at).format('DD/MM/YY')}</p>
                    </div>
                    <div className={classes.content}>
                      <p className='abstract'>{content.abstract}</p>
                      <p
                        className='text_content'
                        dangerouslySetInnerHTML={{
                          __html: content.text_content
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.mobile__footer}>
                  <div>
                    <StyledSVG src={eventMobileSVG} width={30} height={30} />
                  </div>
                  <div>
                    <StyledSVG src={reportMobileSVG} width={30} height={30} />
                  </div>
                  <div>
                    <StyledSVG src={messageMobileSVG} width={30} height={30} />
                  </div>
                  <div>
                    <StyledSVG src={financeMobileSVG} width={30} height={30} />
                  </div>
                  <div className='selected'>
                    <StyledSVG src={socioemotionalSVG} width={30} height={30} />
                  </div>
                </div>
              </div>
            </MobilePreview>
          )
        })}
      </div>

      <Modal
        open={confirmModal.open}
        handleClose={() => setConfirmModal(prev => ({ open: !prev.open }))}
      >
        <div>
          <h1>Atenção!</h1>
          <h2>
            {`Deseja finalizar a criação do tema "${currentTheme?.title}" ?`}
          </h2>
        </div>
        <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
          <Button
            variant='outlined'
            className={classes.cancelBtn}
            onClick={() => {
              setConfirmModal(prev => ({ open: !prev.open }))
            }}
          >
            Cancelar
          </Button>
          <Button
            className={classes.sendBtn}
            variant='contained'
            onClick={async () => {
              await editThemeRSPA({
                id: currentTheme.id,
                status: 'done'
              })
              history.push('/newthemes')
            }}
          >
            Concluir
          </Button>
        </div>
      </Modal>
      <div className={classes.bottomBtns}>
        <Button
          type='button'
          variant={'outlined'}
          className={classes.cancelBtn}
          onClick={() =>
            history.push(`/newthemes/${currentTheme?.id}/contents`)
          }
        >
          Voltar
        </Button>
        <Button
          onClick={() => setConfirmModal(prev => ({ open: true }))}
          className={classes.sendBtn}
        >
          {'Próximo'}
        </Button>
      </div>
    </div>
  )
}

export default ThirdStepThemeForm
