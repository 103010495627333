import React from 'react'
import { useHistory } from 'react-router-dom'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import moment from 'moment'
import { connect } from 'react-redux'
import { downloadImage } from '../../utils/helpers'

import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import MaterialModal from '@material-ui/core/Modal'
import Avatar from '@material-ui/core/Avatar'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import tinycolor from 'tinycolor2'
import ShowFiles from '../shared/ShowFiles'
import viewedSVG from '../../assets/icons/view.svg'
import UserAvatar from '../shared/UserAvatar'

import { backToThePage } from '../../store/pagination/actions'
import Button from '../button/Button'
import MessageForm from '../form/MessageForm'
import SimpleEditorInput from '../shared/SimpleEditorInput'
import Modal from '../modal/Modal'
import CustomTable from '../table/CustomTable'
import SendMsg from '../../assets/icons/send-msg.svg'
import ConfirmModal from '../modal/ConfirmModal'
import { roles, statusCommunication, colors } from '../../utils/constants'
import { getDestinationsByCommunicationRSPA } from '../../store/communications/actions'
import { getDestinationsByClassroomRSPA } from '../../store/classrooms/actions'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '1rem 1.5rem',
    '& h3': {
      color: '#4D5E80',
      marginBottom: '1.8rem'
    },
    '& .MuiTimeline-root': {
      padding: '0 16px',
      margin: 0
    },
    '& .mce-content-body': {
      overflowY: 'scroll !important'
    },
    '& .MuiPaper-root .MuiTableCell-root.MuiTableCell-head': {
      backgroundColor: '#FFF !important'
    },
    [theme.breakpoints.up('md')]: {
      padding: '2rem 3rem'
    },
    [theme.breakpoints.down('426')]: {
      padding: '1rem 0.75rem'
    }
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  },
  button: {
    borderRadius: 8,
    fontWeight: 700,
    textTransform: 'capitalize',
    width: 165,
    height: 44
  },
  spanColor: {
    borderColor: 'transparent',
    '& span:first-of-type': {
      color: '#FFFFFF'
    }
  },
  btnStatus: {
    backgroundColor: '#F4A87C',
    boxShadow: 'none',
    [theme.breakpoints.up('426')]: {
      marginRight: '2rem'
    },
    '&:hover': {
      backgroundColor: '#F4A87C'
    }
  },
  btnEdit: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      backgroundColor: '#00ACDB'
    },
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  },
  hr: {
    margin: '2rem 0 1rem'
  },
  msgHeader: {
    '& div:first-of-type': {
      display: 'flex',
      flexFlow: 'column',
      [theme.breakpoints.up('md')]: {
        flexFlow: 'row wrap'
      },
      '& > p': {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          marginRight: '3rem'
        },
        '& span': {
          color: theme.palette.primary.main,
          fontWeight: 700
        },
        '& .MuiAvatar-root': {
          margin: '0 0.5rem'
        }
      }
    },
    '& div:last-of-type': {
      display: 'flex',
      flexFlow: 'column',
      [theme.breakpoints.up('md')]: {
        flexFlow: 'row wrap'
      },
      '& > p': {
        marginRight: '3rem',
        [theme.breakpoints.up('lg')]: {
          marginRight: '3rem'
        }
      }
    }
  },
  responsiveHeader: {
    [theme.breakpoints.down('426')]: {
      flexDirection: 'row !important',
      justifyContent: 'space-between '
    }
  },
  msgHistory: {
    marginTop: '3rem',
    '& ul:last-child': {
      '& .MuiTimelineSeparator-root .MuiTimelineDot-root': {
        background: theme.palette.info.main,
        boxShadow: `inset 0px 0px 0px 4px #FFF`
      },
      '& .MuiTimelineConnector-root': {
        display: 'none'
      }
    }
  },
  timelineContent: {
    padding: '0 16px',
    marginBottom: '3rem',
    [theme.breakpoints.down('426')]: {
      padding: '0 5px'
    }
  },
  secondaryTail: {
    backgroundColor: '#FFF',
    borderLeft: `1px solid ${theme.palette.info.main}`
  },
  icon: {
    borderColor: theme.palette.info.main,
    boxShadow: 'none',
    height: '1.5rem',
    margin: 0,
    width: '1.5rem'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': {
      textAlign: 'start'
    },
    '& .tox .tox-edit-area__iframe': {
      backgroundColor: '#F6F7F8'
    },
    '& div.tox.tox-tinymce': {
      borderColor: '#F6F7F8',
      height: '400px !important'
    },
    '& .tox-toolbar__primary': {
      background: '#F6F7F8 !important'
    },
    '& .tox.tox-tinymce--toolbar-bottom .tox-editor-header, .tox.tox-tinymce-inline .tox-editor-header': {
      marginBottom: 1
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '30px 40px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    '& button': {
      fontWeight: 700,
      textTransform: 'capitalize'
    }
  },
  observation: {
    backgroundColor: '#F6F7F8',
    '& div': {
      border: 'none'
    }
  },
  obsMsg: {
    color: theme.palette.danger.main,
    textAlign: 'left'
  },
  viewAll: {
    color: '#ADB8CC',
    cursor: 'pointer',
    textDecorationLine: 'underline'
  },
  destination: {
    '& p': {
      alignItems: 'center',
      display: 'flex',
      '& span': {
        fontWeight: 700,
        marginLeft: 7
      }
    }
  },
  files: {
    alignItems: 'end',
    display: 'flex',
    flexFlow: 'row wrap',
    gap: 20,
    '& img': {
      maxHeight: 240,
      maxWidth: 320
    }
  },
  recusedMsgObs: {
    background: tinycolor(theme.palette.secondary.brightest).setAlpha(0.1),
    borderRadius: 16,
    color: theme.palette.secondary.main,
    padding: '1rem 2rem',
    marginBottom: '2rem',
    '& h3': {
      color: theme.palette.secondary.main
    }
  },
  alertMsgText: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: '500 !important'
  },
  finishedMsg: {
    display: 'flex',
    alignItems: 'center',
    '& span:first-of-type': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      marginLeft: '0.5rem'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& div:first-of-type': {
      display: 'flex',
      flexFlow: 'column',
      [theme.breakpoints.up('md')]: {
        flexFlow: 'row wrap'
      },
      [theme.breakpoints.up('426')]: {
        gap: 20
      }
    },
    '& div:last-of-type': {
      display: 'flex',
      flexFlow: 'column',
      [theme.breakpoints.up('426')]: {
        gap: 40
      },
      [theme.breakpoints.up('md')]: {
        flexFlow: 'row wrap'
      }
    }
  },
  groupBtn: {
    display: 'flex',
    gap: 10,
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column',
      '& button:nth-of-type(1)': {
        marginBottom: '1rem'
      }
    }
  },
  container: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  finishBtn: {
    backgroundColor: '#F4A87C',
    width: 256,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    color: '#FFFFF',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    marginTop: 25,
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 146,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  rejectBtn: {
    backgroundColor: '#EF7C8F'
  },
  aproveBtn: {
    backgroundColor: '#375F92'
  },
  avatarStyle: {
    '& span': {
      color: '#6E6E6E',
      fontWeight: 700
    }
  }
}))

function numberFromText (text) {
  // const charCode = text.charCodeAt(0)
  const charCodes = text
    .split('')
    .map(char => char.charCodeAt(0))
    .join('')
  return parseInt(charCodes, 10)
}

const MessageView = ({
  currentCommunication,
  userOccuppations,
  userId,
  observationMessageValue,
  updateCommunication,
  updateMessage,
  respondMessages,
  communicationSuccess,
  selectedSchool,
  cleanSuccess,
  backToThePage,
  currentPagination,
  getDestinationsByCommunicationRSPA,
  getDestinationsByClassroomRSPA
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [observationModal, setObservationModal] = React.useState({})
  const [messageToEditOrRespond, setMessageToEditOrRespond] = React.useState({})
  const [isDestinationsModalOpen, setIsDestinationsModalOpen] = React.useState(
    false
  )
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const [destinationsUsers, setDestinationsUsers] = React.useState([])
  const [messagedestinations, setMessageDestinations] = React.useState([])
  const [destinationsClassrooms, setDestinationsClassrooms] = React.useState([])
  const [isEditing, setIsEditing] = React.useState(false)
  const [isLoadingDestinations, setIsLoadingDestinations] = React.useState(true)
  const [toRespond, setToRespond] = React.useState(
    history.location?.state?.toRespond || {}
  )

  const [modalDetails, setModalDetails] = React.useState({
    open: false,
    details: {}
  })

  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    message: {},
    communicationId: null,
    messageId: null
  })

  React.useEffect(
    () => {
      const loadData = async () => {
        await getDestinationsByCommunicationRSPA({
          communicationId: currentCommunication?.id
        })
          .then(({ data }) => {
            const messageDestinationsFirst = data.data[0].attributes
            if (messageDestinationsFirst.user_id) {
              // when the communication its user by yser
              const allUsers = data.included
                .filter(
                  user => user.id !== String(currentCommunication.origin_id)
                )
                .map(user => ({ id: user.id, ...user.attributes }))
              setDestinationsUsers(
                prevState =>
                  prevState?.length > 0 ? [...prevState, ...allUsers] : allUsers
              )
            } else {
              data.data.forEach(destination => {
                setMessageDestinations(
                  prevState =>
                    prevState
                      ? [...prevState, destination.attributes]
                      : [destination.attributes]
                )
                const classroomDestination =
                  destination.attributes.classroom_id ||
                  destination.attributes.classroom_children_destination_id ||
                  destination.attributes.classroom_parents_destination_id ||
                  destination.attributes.classroom_teachers_destination_id

                // classroom_id -> mensagem enviada para toda turma
                // classroom_children_destination_id -> mensagem enviada para todos os estudantes da turma
                // classroom_parents_destination_id -> mensagem enviada para todos responsáveis da turma
                // classroom_teachers_destination_id -> mensagem enviada para todos os professores da turma

                const includes =
                  (destination.attributes.classroom_id &&
                    'teachers,children.parents,children.user') ||
                  (destination.attributes.classroom_children_destination_id &&
                    'children.user') ||
                  (destination.attributes.classroom_parents_destination_id &&
                    'children.parents') ||
                  (destination.attributes.classroom_teachers_destination_id &&
                    'teachers')
                const params = {
                  'q[id_eq]': classroomDestination,
                  'page[size]': 20,
                  include: includes
                }
                classroomDestination &&
                  getClassroomUsers(params).then(response => {
                    const destinationsData = []
                    response.data.forEach(classroom => {
                      setDestinationsClassrooms(
                        prevState =>
                          prevState?.length > 0
                            ? [
                                ...prevState,
                                {
                                  id: classroom.id,
                                  name: classroom.attributes.name
                                }
                              ]
                            : [
                                {
                                  id: classroom.id,
                                  name: classroom.attributes.name
                                }
                              ]
                      )
                    })
                    if (destination.attributes.classroom_id) {
                      const allUsers = response.included
                        .filter(user => user.type === 'users')
                        .map(user => ({ id: user.id, ...user.attributes }))
                      destinationsData.push(allUsers)
                    }
                    if (
                      destination.attributes.classroom_children_destination_id
                    ) {
                      const childs = response.included
                        .filter(
                          user =>
                            user.type === 'users' &&
                            user.attributes.occupations.includes('student')
                        )
                        .map(user => ({ id: user.id, ...user.attributes }))
                      destinationsData.push(childs)
                    }
                    if (
                      destination.attributes.classroom_parents_destination_id
                    ) {
                      const childParents = response.included
                        .filter(
                          user =>
                            user.type === 'users' &&
                            user.attributes.occupations.includes('parent')
                        )
                        .map(user => ({ id: user.id, ...user.attributes }))
                      destinationsData.push(childParents)
                    }
                    if (
                      destination.attributes.classroom_teachers_destination_id
                    ) {
                      const teachers = response.included
                        .filter(
                          user =>
                            user.type === 'users' &&
                            user.attributes.occupations.includes('teacher')
                        )
                        .map(user => ({ id: user.id, ...user.attributes }))
                      destinationsData.push(teachers)
                    }
                    const newDestionationsData = destinationsData
                      .flat()
                      ?.filter(
                        (user, index, self) =>
                          index === self.findIndex(us => us.id === user.id)
                      )
                    setDestinationsUsers(
                      prevState =>
                        prevState?.length > 0
                          ? [...prevState, ...newDestionationsData]
                          : newDestionationsData
                    )
                  })
              })
            }
          })
          .catch(err => console.error(err))
        setIsLoadingDestinations(false)
      }
      loadData()
    },
    [currentCommunication]
  )

  const getClassroomUsers = params => {
    return getDestinationsByClassroomRSPA({ params })
      .then(res => res.data)
      .catch(err => console.error(err))
  }

  const handleOpenEdit = () => {
    setIsEditing(prevState => !prevState)
    setMessageToEditOrRespond({})
  }

  const handleMessageToEdit = message => {
    setIsEditing(prevState => !prevState)
    setMessageToEditOrRespond(message)
  }

  const handleOpenResponse = message => {
    const messageDestinationClone = [...messagedestinations]

    // remove null
    messageDestinationClone.forEach(destinationClone => {
      delete destinationClone.communication_id
      delete destinationClone.message_id
      delete destinationClone.updated_at
      delete destinationClone.user_id
      Object.keys(destinationClone).forEach(key => {
        if (destinationClone[key] === null) {
          delete destinationClone[key]
        }
      })
    })
    const destinationToResponde = messageDestinationClone.filter(element => {
      if (Object.keys(element).length !== 0) {
        return true
      }
      return false
    })
    setToRespond(
      message?.id ? { ...message, destination: destinationToResponde } : {}
    )
  }

  const accessModerationOptions = [
    roles.SCHOOL_MODERATOR,
    roles.SCHOOL_ADMIN,
    roles.VERSAR_ADMIN
  ]
  const accessOnlyToReadAndResponde = [roles.TEACHER]

  const hasAccess = permissionList => {
    const hasAccess = userOccuppations
      ? userOccuppations.filter(occupation =>
          permissionList.some(permit => occupation === permit)
        )
      : []
    return hasAccess.length > 0
  }

  const handleObservationModal = message => {
    setObservationModal(message?.id ? message : {})
    // pegar a obs e enviar como msg
    // e atualizar status para refuse
  }

  const handleDestinationsModal = () => {
    setIsDestinationsModalOpen(prevState => !prevState)
  }

  const destinationscolumns = [
    {
      key: 'destination',
      name: 'Destinatários',
      width: '35%',
      render: function render (row) {
        return (
          <div className={classes.destination}>
            <UserAvatar
              userName={row.name}
              avatarUrl={row.avatar_url}
              style={{ color: theme.palette.primary.main, fontWeight: 700 }}
            />
          </div>
        )
      }
    },
    {
      key: 'email',
      name: 'Email',
      width: '35%',
      render: row => {
        return row.email
      }
    },
    {
      key: 'relationationship',
      name: 'Relacionamento',
      width: '30%',
      render: row => {
        const related =
          (row.occupations?.includes('parent') && 'Responsável') ||
          (row.occupations?.includes('student') && 'Estudante') ||
          (row.occupations?.includes('school_administrator') &&
            'Administrador da escola') ||
          (row.occupations?.includes('teacher') && 'Docente') ||
          (row.occupations?.includes('administrator') && 'Administrador') ||
          (row.occupations?.includes('school_moderator') && 'Moderador') ||
          ''
        return related
      }
    }
  ]

  const topMessages = hasAccess(accessModerationOptions)
    ? currentCommunication.messages
        ?.filter(message => {
          return (
            !message.message_type.includes('system') &&
            (message.status.includes('pending') ||
              (message.status.includes('open') &&
                !message.moderator_id &&
                String(message.author_id) !== String(userId)))
          )
        })
        .slice(0, 1)
    : currentCommunication.messages && [
        currentCommunication.messages[currentCommunication.messages.length - 1]
      ]

  const recusedMsg = currentCommunication.messages?.find(
    message => message.status === 'refused'
  )
  const handleImageClick = file => {
    downloadImage(file)
  }

  const handleOpenDetails = details => {
    setModalDetails(prev => {
      const newState = { ...prev, open: !prev.open, details }
      return newState
    })
  }

  // Message
  const TimeLineStyled = ({ message }) => {
    const systemColor = numberFromText('Sy') % colors.length

    const aprovedMessage = 'Sua Mensagem foi aprovada:'
    let newMessageContent
    if (
      message.message_type === 'system' &&
      message.content?.includes(aprovedMessage)
    ) {
      newMessageContent = message.content
      newMessageContent = newMessageContent.replace(`"<p>`, `<p>"`)
      newMessageContent = newMessageContent.replace(`</p>"`, `"</p>`)
    } else {
      newMessageContent = message.content
    }

    return (
      <Timeline>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant='outlined' className={classes.icon} />
            <TimelineConnector color='info' className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent className={classes.timelineContent}>
            <div className={classes.msgHeader}>
              <div className={classes.responsiveHeader}>
                {message.author && (
                  <p style={{ marginTop: -10 }}>
                    <UserAvatar
                      userName={message.author.name}
                      avatarUrl={message.author.avatar_url}
                      style={
                        !matches
                          ? {
                              color: theme.palette.primary.main,
                              fontWeight: 700,
                              fontSize: '12px'
                            }
                          : {
                              color: theme.palette.primary.main,
                              fontWeight: 700
                            }
                      }
                    />
                  </p>
                )}
                {message.message_type.includes('system') && (
                  <p style={{ marginTop: -10 }}>
                    <Avatar
                      style={{
                        backgroundColor: colors[systemColor]
                      }}
                    >
                      S
                    </Avatar>
                    <span>Mensagem do sistema</span>
                  </p>
                )}
                {matches ? (
                  <p style={{ marginTop: -10 }}>
                    {message.created_at && (
                      <>
                        Enviada:{' '}
                        {moment(message.created_at).format(
                          'DD/MM/YYYY - HH:mm'
                        )}
                      </>
                    )}
                  </p>
                ) : (
                  <Tooltip title={'Visualizar detalhes'}>
                    <img
                      src={viewedSVG}
                      alt='icone visualizados'
                      width='19'
                      height='13'
                      onClick={() => handleOpenDetails({ message })}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            <h3 style={{ marginBottom: '1rem' }}>{message.title || ''}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: newMessageContent
              }}
            />
            {/* {messageFilesContainer(message)} */}
            {
              <ShowFiles
                allFiles={message.files}
                handleImageClick={handleImageClick}
                onlyView
              />
            }
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    )
  }

  const TimeLineAlertStyled = ({ alertTypeTxt, user, date }) => {
    return (
      <Timeline>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant='outlined' className={classes.icon} />
            <TimelineConnector color='info' className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent className={classes.timelineContent}>
            <div className={classes.msgHeader}>
              <div className={classes.responsiveHeader}>
                {user && (
                  <p style={{ marginTop: -10 }}>
                    <UserAvatar
                      userName={user.name}
                      avatarUrl={user.avatar_url}
                      style={
                        !matches
                          ? {
                              color: theme.palette.primary.main,
                              fontWeight: 700,
                              fontSize: '12px'
                            }
                          : {
                              color: theme.palette.primary.main,
                              fontWeight: 700
                            }
                      }
                    />
                  </p>
                )}
                {matches ? (
                  <p
                    style={{ marginTop: -10 }}
                    className={
                      alertTypeTxt === 'Recusada' ||
                      alertTypeTxt === 'Finalizada'
                        ? classes.alertMsgText
                        : undefined
                    }
                  >
                    {alertTypeTxt}:{' '}
                    {date && moment(date).format('DD/MM/YYYY - HH:mm')}
                  </p>
                ) : (
                  <Tooltip title={'Visualizar detalhes'}>
                    <img
                      src={viewedSVG}
                      alt='icone visualizados'
                      width='19'
                      height='13'
                      onClick={() => handleOpenDetails({ alertTypeTxt, date })}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    )
  }

  const originChildrenName = currentCommunication.origin?.children
    ?.map(item => {
      return item.name
    })
    .join(', ')

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.header}>
            <div>
              {currentCommunication.origin && (
                <>
                  <p>Remetente: </p>
                  <Tooltip
                    title={
                      originChildrenName
                        ? `Filho(s): ${originChildrenName}`
                        : ''
                    }
                  >
                    <div>
                      <UserAvatar
                        userName={currentCommunication.origin.name}
                        avatarUrl={currentCommunication.origin.avatar_url}
                        className={classes.avatarStyle}
                      />
                    </div>
                  </Tooltip>
                </>
              )}
              <p style={{ marginRight: 0 }}>Destinatários: </p>
              {isLoadingDestinations && (
                <CircularProgress
                  style={{ alignSelf: 'center', marginLeft: 10 }}
                  size={24}
                  color='secondary'
                />
              )}
              {destinationsUsers &&
                destinationsUsers.map((destination, index) => {
                  const destinationChildrenName = destination?.children
                    ?.map(item => {
                      return item.name
                    })
                    .join(', ')
                  return (
                    index < 2 && (
                      <Tooltip
                        title={
                          destinationChildrenName
                            ? `Filho(s): ${destinationChildrenName}`
                            : ''
                        }
                      >
                        <div style={!matches ? { marginBottom: '10px' } : {}}>
                          <UserAvatar
                            className={classes.avatarStyle}
                            userName={destination.name}
                            avatarUrl={destination.avatar_url}
                          />
                        </div>
                      </Tooltip>
                    )
                  )
                })}
              {destinationsUsers &&
                destinationsUsers.length > 2 && (
                  <p
                    onClick={handleDestinationsModal}
                    className={classes.viewAll}
                  >
                    Visualizar todos
                  </p>
                )}
            </div>
            <div>
              <p>
                Criada em:{' '}
                <strong>
                  {moment(currentCommunication.created_at).format(
                    'DD/MM/YYYY - HH:mm'
                  )}
                </strong>
              </p>
              <p>
                Status:{' '}
                <strong>
                  {currentCommunication.active
                    ? statusCommunication[currentCommunication.status]
                    : 'Finalizada'}
                </strong>
              </p>
              {currentCommunication.classroom && (
                <>
                  <p>
                    Série:{' '}
                    <strong>
                      {currentCommunication.classroom?.grade?.name}
                    </strong>
                  </p>
                  <p>
                    Turma:{' '}
                    <strong>{currentCommunication.classroom?.name}</strong>
                  </p>
                </>
              )}
              {!currentCommunication.classroom &&
                !!destinationsClassrooms?.length > 0 && (
                  <p>
                    Turmas:{' '}
                    {destinationsClassrooms.map((classroomName, index) => (
                      <strong key={`classroom-${classroomName.name}`}>
                        {classroomName.name}{' '}
                        {index < destinationsClassrooms.length - 1 ? ' | ' : ''}
                      </strong>
                    ))}
                  </p>
                )}
              {(selectedSchool?.id ||
                userOccuppations?.includes(roles.VERSAR_ADMIN)) && (
                <p>
                  Escola: <strong>{currentCommunication.school?.name}</strong>
                </p>
              )}
            </div>
          </div>
          <Divider className={classes.hr} variant='middle' component='hr' />
        </Grid>
        <Grid item xs={12}>
          {/*
            when edit message - send status open
            when refused message - send status refused
          */}
          {/* edit only with status pending */}
          {isEditing ? (
            <MessageForm
              updateMessage={updateMessage}
              initialValues={messageToEditOrRespond}
              handleOpen={handleOpenEdit}
            />
          ) : (
            <div>
              {/* observation of refused message */}

              {/* só exibir a mensagem de observação se o parent_id da mensagem recusada e do topMessage forem iguais */}
              {recusedMsg?.id &&
                userOccuppations &&
                userOccuppations.includes(roles.TEACHER) &&
                currentCommunication.messages?.length > 0 &&
                currentCommunication.messages
                  .filter(message => {
                    const sameParentId = topMessages?.find(
                      msg =>
                        msg.parent_id !== null &&
                        msg.parent_id === message.parent_id
                    )
                    return (
                      message.message_type !== 'system' &&
                      String(message.author_id) !== String(userId) &&
                      !!sameParentId?.id
                    )
                  })
                  .map(message => {
                    return (
                      <div
                        className={classes.recusedMsgObs}
                        key={`recusedMsg-${message.id}`}
                      >
                        <h3>{message.title || ''}</h3>
                        <div
                          style={{ marginBottom: '2rem' }}
                          dangerouslySetInnerHTML={{
                            __html: message.content
                          }}
                        />
                      </div>
                    )
                  })}
              {topMessages?.length > 0 &&
                topMessages.map(message => {
                  return (
                    <div key={`top-message-${message.id}`}>
                      {hasAccess(accessModerationOptions) &&
                        currentCommunication.active &&
                        message.status.includes('pending') && (
                          <div className={classes.btnEdit}>
                            <Button
                              className={`${classes.button}`}
                              style={{
                                padding: '14px 48px',
                                gap: 10,
                                backgroundColor: '#386093'
                              }}
                              onClick={() => handleMessageToEdit(message)}
                            >
                              Editar
                            </Button>
                          </div>
                        )}
                      <div key={`msg-${message.id}`}>
                        <h3>{message.title || ''}</h3>
                        <div
                          style={{ marginBottom: '2rem' }}
                          dangerouslySetInnerHTML={{
                            __html: message.content
                          }}
                        />
                        <div style={{ marginBottom: '4rem' }}>
                          <ShowFiles
                            allFiles={message.files}
                            handleImageClick={handleImageClick}
                            onlyView
                          />
                        </div>
                      </div>
                      {hasAccess(accessModerationOptions) &&
                        currentCommunication.active &&
                        message.status.includes('pending') && (
                          <div className={classes.btnContainer}>
                            <Button
                              className={`${classes.button} ${
                                classes.rejectBtn
                              }`}
                              style={{ marginRight: '1rem' }}
                              color='secondary'
                              onClick={() => handleObservationModal(message)}
                            >
                              Recusar
                            </Button>
                            <Button
                              className={`${classes.button} ${
                                classes.aproveBtn
                              }`}
                              onClick={() =>
                                setConfirmModal({
                                  messageId: message.id,
                                  title: 'Deseja aprovar a mensagem?',
                                  message,
                                  open: true
                                })
                              }
                            >
                              Aprovar
                            </Button>
                          </div>
                        )}
                      {/*
                      condições que não podem aparecer o Responder
                        - quando a conversa for com mais de um usuário
                        - quando a conversação tiver finalizada
                        - quando tiver com o status pendente
                      */}
                      {!isLoadingDestinations &&
                        (hasAccess(accessOnlyToReadAndResponde) ||
                          (hasAccess(accessModerationOptions) &&
                            (message.status.includes('open') ||
                              message.status.includes('done')))) &&
                        !isLoadingDestinations &&
                        !toRespond?.id &&
                        destinationsUsers?.length < 2 &&
                        !message.moderator_id &&
                        message.status !== 'pending' &&
                        currentCommunication.messages.findIndex(
                          msg =>
                            String(msg.parent_id) === String(message.id) &&
                            msg.message_type !== 'system'
                        ) === -1 &&
                        !currentCommunication.messages.find(
                          message => message.status === 'pending'
                        ) &&
                        // !message.message_type.includes('system') &&
                        currentCommunication.active && (
                          <div className={classes.btnContainer}>
                            <Button
                              className={classes.button}
                              onClick={() => handleOpenResponse(message)}
                            >
                              Responder
                            </Button>
                          </div>
                        )}
                    </div>
                  )
                })}
              {hasAccess(accessModerationOptions) &&
                topMessages?.length === 0 &&
                'Sem mensagens para moderar'}

              {!!toRespond?.id && (
                <MessageForm
                  respondMessages={respondMessages}
                  initialValues={{
                    destinationsId: destinationsUsers.concat(
                      currentCommunication.origin
                    ),
                    toResponde: toRespond.id,
                    //  parentId: toRespond.id,
                    communicationId: toRespond.communication_id
                  }}
                  handleOpen={handleOpenResponse}
                />
              )}
            </div>
          )}
          <Divider className={classes.hr} variant='middle' component='hr' />
        </Grid>
        <Grid item xs={12}>
          <h3>Histórico de Mensagens</h3>
          <div className={classes.msgHistory}>
            {currentCommunication.messages &&
              currentCommunication.messages.map((message, index) => {
                return (
                  <React.Fragment key={`message[${message.id}]`}>
                    <TimeLineStyled message={message} />
                    {message.message_views &&
                      message.message_views.map(viewed => {
                        return (
                          <TimeLineAlertStyled
                            key={`viewed-${viewed.id}`}
                            alertTypeTxt='Visualizada'
                            user={viewed.user}
                            date={viewed.created_at}
                          />
                        )
                      })}
                    {message.status === 'refused' &&
                      message.moderator_id &&
                      message.refused_at &&
                      message.moderator && (
                        <TimeLineAlertStyled
                          alertTypeTxt='Recusada'
                          user={message.moderator}
                          date={message.refused_at}
                        />
                      )}
                    {message.status === 'open' &&
                      message.moderator_id &&
                      message.moderated_at &&
                      message.moderator && (
                        <TimeLineAlertStyled
                          alertTypeTxt='Moderada'
                          user={message.moderator}
                          date={message.moderated_at}
                        />
                      )}
                    {!!message.viewed_by_moderator_at && (
                      <TimeLineAlertStyled
                        alertTypeTxt='Visualizada'
                        user={
                          (message.moderator && {
                            name: message.moderator.name + ' (Moderador)'
                          }) || { name: 'Moderador' }
                        }
                        date={message.viewed_by_moderator_at}
                      />
                    )}
                    {index === currentCommunication.messages.length - 1 &&
                      currentCommunication.finished_at &&
                      currentCommunication.finished_by && (
                        <TimeLineAlertStyled
                          alertTypeTxt='Finalizada'
                          user={currentCommunication.finished_by}
                          date={currentCommunication.finished_at}
                        />
                      )}
                  </React.Fragment>
                )
              })}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <div className={`${classes.btnContainer} ${classes.groupBtn}`}>
          {hasAccess(accessModerationOptions) &&
            currentCommunication.active !== false && (
              <div>
                <Button
                  className={classes.finishBtn}
                  style={{ lineHeight: 'unset' }}
                  onClick={() =>
                    setConfirmModal({
                      communicationId: currentCommunication.id,
                      title:
                        'Você deseja realmente alterar o status dessa mensagem para finalizado ?',
                      open: true
                    })
                  }
                >
                  Finalizar atendimento
                </Button>
              </div>
            )}
          <Button
            className={classes.backBtn}
            variant='outlined'
            onClick={() => {
              backToThePage({ ...currentPagination, canGoBack: true })
              history.goBack()
            }}
          >
            Voltar
          </Button>
        </div>
      </Grid>
      <ConfirmModal
        title={confirmModal.title}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({
              messageId: null,
              communicationId: null,
              title: '',
              open: value
            })
          }
        }}
        onConfirm={() => {
          // change communication status
          confirmModal.communicationId &&
            updateCommunication({
              id: confirmModal.communicationId,
              active: false
            })
          confirmModal.message &&
            updateMessage({
              id: confirmModal.messageId,
              data: { status: 'open' }
            })
        }}
      />
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={modalDetails.open}
        onClose={() => handleOpenDetails({})}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        // style={{padding: '20px'}}
      >
        <Fade in={modalDetails.open}>
          <div
            className={classes.paper}
            style={{
              maxWidth: '90%',
              minHeight: '200px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <div>
              <h3
                style={{ lineHeight: 'unset', textAlign: 'left', marginTop: 0 }}
              >
                Detalhes da mensagem:
              </h3>
            </div>
            {modalDetails.details.message ? (
              <p style={{ marginTop: -10 }}>
                {modalDetails.details.message.created_at && (
                  <>
                    Enviada:{' '}
                    {moment(modalDetails.details.message.created_at).format(
                      'DD/MM/YYYY - HH:mm'
                    )}
                  </>
                )}
              </p>
            ) : (
              <p
                style={{ marginTop: -10 }}
                className={
                  modalDetails.details.alertTypeTxt === 'Recusada' ||
                  modalDetails.details.alertTypeTxt === 'Finalizada'
                    ? classes.alertMsgText
                    : undefined
                }
              >
                {modalDetails.details.alertTypeTxt}:{' '}
                {modalDetails.details.date &&
                  moment(modalDetails.details.date).format(
                    'DD/MM/YYYY - HH:mm'
                  )}
              </p>
            )}
          </div>
        </Fade>
      </MaterialModal>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={!!observationModal?.id}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={!!observationModal?.id}>
          <div className={classes.paper}>
            <h3 id='transition-modal-title'>Observação:</h3>
            <div className={classes.observation}>
              <Field
                name='observationMessage'
                component={SimpleEditorInput}
                characterLimit={760}
                // characterMin={60}
              />
            </div>
            <div className={classes.btnContainer}>
              <Button
                className={classes.button}
                color='secondary'
                style={{ marginRight: '1rem' }}
                variant='contained'
                onClick={handleObservationModal}
              >
                Cancelar
              </Button>
              <Button
                className={classes.button}
                color='primary'
                variant='contained'
                onClick={async () => {
                  // send message
                  // send observation
                  // send update refusing message
                  // reply the message stating the reason for the refusal
                  observationModal?.id &&
                    (await updateMessage({
                      id: observationModal.id,
                      data: { status: 'refused' }
                    }))
                  observationModal?.id &&
                    (await respondMessages({
                      title: 'Observação',
                      content: observationMessageValue,
                      communication_id: observationModal.communication_id,
                      parent_id: observationModal.id,
                      message_destinations_attributes: [
                        { user_id: observationModal.author_id }
                      ]
                    }))
                }}
              >
                Enviar
              </Button>
            </div>
          </div>
        </Fade>
      </MaterialModal>
      <Modal open={communicationSuccess} handleClose={cleanSuccess}>
        <img width='400px' src={SendMsg} alt='icone de mensagem enviada' />
        <h3 id='transition-modal-title'>Mensagem enviada com sucesso</h3>
        <Button
          className={classes.continueBtn}
          onClick={() => history.push('/messages/inbox')}
        >
          Continuar
        </Button>
      </Modal>
      <Modal
        open={isDestinationsModalOpen}
        handleClose={setIsDestinationsModalOpen}
      >
        <h2 style={{ color: '#555', float: 'left' }}>Informações</h2>
        {destinationsUsers &&
          !!destinationsUsers.length && (
            <CustomTable
              columns={destinationscolumns}
              whiteHeader
              data={destinationsUsers}
            />
          )}
      </Modal>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('messageView')
  return {
    observationMessageValue: selector(state, 'observationMessage'),
    currentPagination: state.pagination
  }
}
const mapDispatchToProps = dispatch => ({
  backToThePage: data => dispatch(backToThePage(data)),
  getDestinationsByCommunicationRSPA: data =>
    dispatch(getDestinationsByCommunicationRSPA.request(data)),
  getDestinationsByClassroomRSPA: data =>
    dispatch(getDestinationsByClassroomRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'messageView'
  })(MessageView)
)
