import React from 'react'
import { connect } from 'react-redux'
import {
  reduxForm,
  Form,
  Field,
  formValueSelector,
  getFormValues,
  change
} from 'redux-form'
import { useHistory } from 'react-router-dom'

import CheckboxInput from '../input/CheckboxInput'
import {
  TextField,
  FormControlLabel,
  Radio,
  Typography
} from '@material-ui/core'
import { frequencyTags, roles } from '../../utils/constants'

import AsyncSelect from '../shared/AsyncSelect'
import { showAlertMessage } from '../../store/alert/actions'
import Chip from '../shared/Chip'
import Button from '../button/Button'
import TextArea from '../shared/TextArea'
import { convertArrayToObject } from '../../utils/helpers'
import UserAvatar from '../shared/UserAvatar'
import ReactSelect from '../select/ReactSelect'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  editReportStudent,
  editReportStudentByClassroom
} from '../../store/reports/actions'
import { getTagsRSPA } from '../../store/tags/actions'
import DatePickerInput from '../shared/DatePickerInput'
import MessageConfirmationSVG from '../../assets/icons/msg-confirmation.svg'
import StyledSVG from '../shared/StyledSVG'
import moderatingSVG from '../../assets/icons/moderating-icon.svg'
import withoutBulletinsSVG from '../../assets/icons/without-bulletins.svg'
import DropzoneInput from '../shared/DropzoneInput'
import ShowFiles from '../shared/ShowFiles'

import activitySVG from '../../assets/icons/activity-board.svg'
import Modal from '../modal/Modal'

import { useReportSteps } from '../../utils/useReportSteps'
import { makeStyles, withStyles, styled, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import FormControl from '@material-ui/core/FormControl'

import RadioGroup from '@material-ui/core/RadioGroup'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const StyledTableRow = withStyles(theme => ({
  root: {
    '& td': {
      borderBottom: '1px solid #EFEFEF'
    }
  }
}))(TableRow)

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  table: {
    [theme.breakpoints.up('lg')]: {
      minWidth: 700
    },
    '& span': {
      color: '#ADB8CC'
    }
  },
  root: {
    '& .MuiFormControl-root': {
      display: 'flex',
      marginBottom: 20,
      '& > .MuiFormGroup-row': {
        gap: 20
      },
      '& .MuiSvgIcon-root': {
        height: 18,
        width: 18
      }
    },
    '& .MuiGrid-container': {
      minWidth: 250,
      width: 250,
      [theme.breakpoints.down('426')]: {
        width: '130px',
        minWidth: 'unset'
      }
    },
    '& textarea': {
      maxWidth: 1000,
      '&::placeholder': {
        color: theme.palette.text.secondary
      },
      [theme.breakpoints.down('426')]: {
        height: '80px'
      }
    }
  },
  fieldWithText: {
    marginBottom: '25px',
    maxWidth: '1000px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subLabel: {
    color: '#555',
    fontSize: 16,
    fontWeight: 700
  },
  grayName: {
    color: theme.palette.text.secondary
  },
  fieldRow: {
    alignItems: 'stretch',
    display: 'flex',
    gap: 10,
    '& .MuiAvatar-root': {
      height: 26,
      width: 26
    }
  },
  continueBtn: {
    backgroundColor: '#386093',
    width: 179,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  exitBtn: {
    width: 100,
    backgroundColor: '#EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  cancelBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  selectField: {
    display: 'inline-table',
    marginRight: 50
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: '0.8rem'
  },
  labelGrade: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      marginLeft: '5px',
      fontSize: 18
    }
  },
  dashedBorder: {
    marginTop: '25px',
    width: '100%',
    height: '0px',
    border: '1px dashed #ADB8CC'
  },
  subjectContainer: {
    marginLeft: '100px',
    [theme.breakpoints.down('426')]: {
      marginLeft: '0px',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    [theme.breakpoints.up('426')]: {
      '& span': {
        marginRight: '15px'
      }
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      marginLeft: '10px'
    }
  },
  optionTopic: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60%',
    [theme.breakpoints.down('426')]: {
      maxWidth: 'calc(100% + 32px)'
    }
  },
  progressContainer: {
    marginBottom: '30px'
  },
  confirmModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  rowHead: {
    '& th.MuiTableCell-root': {
      [theme.breakpoints.down('376')]: {
        padding: '5px !important'
      }
    }
  },
  navegationSection: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div#navigation': {
      display: 'flex',
      flexDirection: 'row',
      gap: 10
    },
    [theme.breakpoints.down('475')]: {
      justifyContent: 'center'
    }
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1)
  },
  tableCell: {
    padding: theme.spacing(1),
    textAlign: 'left',
    [theme.breakpoints.up('425')]: {
      marginRight: theme.spacing(5)
    }
  },
  tableRoot: {
    display: 'flex',
    flexDirection: 'column'
  },
  multipleChoiceArea: {
    flex: 1,
    width: '-webkit-fill-available',
    '& .MuiCheckbox-root': {
      paddingLeft: 0
    },
    '& .MuiTextField-root': {
      maxWidth: '400px',
      '& label': {
        [theme.breakpoints.down('425')]: {
          fontSize: '0.7rem'
        }
      }
    }
  },
  multipleChoiceAvatar: {
    [theme.breakpoints.down('425')]: {
      '& .MuiAvatar-root': {
        display: 'none !important'
      }
    }
  },
  tableHead: {
    color: '#555555',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    '&:nth-of-type(2)': {
      paddingLeft: '25vw'
    }
  },
  selectSymptoms: {
    display: 'flex',
    gap: '10px',
    color: '#868E96',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400
  },
  subjectContainerToParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '1000px',
    marginBottom: '10px'
  },
  noAnswersToModerateDiv: {
    '& h5': {
      textAlign: 'center',
      maxWidth: '80%',
      color: '#555555'
    }
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  inputsWrapper: {
    display: 'flex',
    gap: 20
  },
  multipleChoiceInputsWrapper: {
    flex: '1 !important'
  },
  dropzoneRootStyle: {
    flex: 1,
    '& div': {
      height: 140,
      maxWidth: 'unset !important'
    }
  },
  textAreaRoot: {
    flex: 2
  },
  multipleChoiceDropzone: {
    height: 'fit-content'
  }
}))

const NoAnswersToModerateStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 16,
  marginTop: 18,
  marginBottom: 18,
  '& h5': {
    fontSize: 18
  },
  [theme.breakpoints.down('sm')]: {
    '& h5': {
      fontSize: 18
    }
  },
  '& img': {
    objectFit: 'contain',
    width: '100%',
    maxWidth: 450
  }
}))

const ReportStudentByClassroomForm = ({
  reports,
  fieldValues,
  touch,
  reportId,
  isFetching,
  isSuccess,
  initialValues,
  handleSubmit,
  submitSuccess,
  filesForAllStudents,
  onSubmit,
  change,
  userId,
  alert,
  userOccupations,
  valid,
  filesToDelete,
  getTagsRSPA
}) => {
  const classes = useStyles()
  const history = useHistory()
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const isModerate =
    reports?.currentItem?.status === 'waiting_moderation' && isAdmin
  const formComponents =
    reports.reportOptions.length > 0 &&
    reports.reportOptions.map(reportQuestion => reportQuestion).filter(item => {
      const tagsForModerate = ['subject', 'text', 'multiple_choices']
      if (isModerate) {
        return (
          tagsForModerate.includes(item.tag.tag_type) ||
          +item.tag.tag_order === 1 ||
          +item.tag.tag_order === 2
        )
      } else {
        return true
      }
    })
  const [tagTypeContent, setTagTypeContent] = React.useState()
  const [currentTag, setCurrentTag] = React.useState(1)
  const [tagProps, setTagProps] = React.useState()
  const [filesForAll, setFilesForAll] = React.useState(filesForAllStudents)
  const [confirmExitForm, setConfirmExitForm] = React.useState(false)
  const [confirmStatusModal, setConfirmStatusModal] = React.useState(false)
  const [canSkipFromFirstTag, setCanSkipFromFirstTag] = React.useState(false)
  const [canSkipToNextTag, setCanSkipToNextTag] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const [reportStudentsReady, setReportStudentsReady] = React.useState(false)

  const currentFrequencyTag = reports.reportOptions.filter(item => {
    return (
      item.tag.name === frequencyTags['frequencyForAllName'] ||
      item.tag.name === frequencyTags['frequencyForSubjectName']
    )
  })?.[0]?.tag?.name

  // determina o nome padrão do campo de frequencia que será tratado no report
  const frequencyNameField =
    currentFrequencyTag === frequencyTags['frequencyForAllName']
      ? frequencyTags['frequencyForAll']
      : frequencyTags['frequencyForSubject']

  const fullNameField =
    currentFrequencyTag === frequencyTags['frequencyForAllName']
      ? frequencyNameField
      : `${frequencyNameField}-${fieldValues?.subjectValue?.label}`

  const {
    currentStep,
    currentComponent,
    changeStep,
    isLastStep,
    isFirstStep
  } = useReportSteps(formComponents)

  const handleToggle = () => {
    valid && setOpen(!open)
  }

  const NoAnswersToModerateDiv = () => (
    <NoAnswersToModerateStyled className={classes.noAnswersToModerateDiv}>
      <img src={withoutBulletinsSVG} alt='imagem da lista de diários vazia' />
      <Typography variant='h5' color='primary'>
        O diário selecionado não possui campos textuais que necessitam
        moderação, mas ainda é necessário finalizar o envio do diário para que
        os destinatários recebam as informações.
      </Typography>
    </NoAnswersToModerateStyled>
  )

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#F6F7F8',
      color: '#555555',
      fontWeight: 700,
      borderBottom: '1px solid #EFEFEF'
    },
    body: {
      fontSize: 14
    }
  }))(TableCell)

  React.useEffect(
    () => {
      if (filesForAllStudents && filesForAll.length < 1) {
        setFilesForAll(filesForAllStudents)
      }
    },
    [filesForAllStudents]
  )

  // informa se os estudantes ja foram carregados
  React.useEffect(
    () => {
      if (
        reports?.reportStudents?.items?.length > 0 &&
        reports?.reportOptions?.length > 0 &&
        isSuccess
      ) {
        setReportStudentsReady(true)
      }
    },
    [isSuccess]
  )

  // validação para poder avançar para a proxima página
  React.useEffect(
    () => {
      // checagem do preenchimento do primeiro campo (frequência)
      const studentsFrequency =
        fieldValues &&
        Object.entries(fieldValues)
          .map(([key, value]) => {
            if (value?.[fullNameField]) {
              return {
                [key.split('_')[1]]: value?.[fullNameField]?.answer
              }
            }
          })
          .filter(item => item !== undefined)
      if (
        studentsFrequency?.length === reports?.reportStudents?.items?.length
      ) {
        reports?.reportStudents?.items?.length > 0 &&
          setCanSkipFromFirstTag(true)
      } else {
        setCanSkipFromFirstTag(false)
      }

      // checagem do preenchimento dos demais campos
      const frequencysTags =
        +currentComponent?.tag?.tag_order === 1 ||
        +currentComponent?.tag?.tag_order === 2
      let allFieldsFilled = true
      !frequencysTags &&
        reports?.reportStudents?.items.forEach(student => {
          if (
            fieldValues?.[`fieldsValues_${student.id}`]?.[fullNameField]
              ?.answer === 'Ausente'
          ) {
            return
          }
          const fieldValue = currentComponent?.tag?.tag_options.every(
            option => {
              return fieldValues?.[`fieldsValues_${student.id}`]?.[
                `${currentComponent.tag.name}-${option.topic}`
              ]
            }
          )
          if (!fieldValue) {
            allFieldsFilled = false
          }
        })
      setCanSkipToNextTag(allFieldsFilled)
    },
    [fieldValues, currentComponent]
  )

  React.useEffect(
    () => {
      submitSuccess && setConfirmStatusModal(prev => !prev)
    },
    [submitSuccess]
  )

  // dispara a função para realizar a troca do conteúdo do formulário
  React.useEffect(
    () => {
      currentComponent && handleSelectionChanged(currentComponent)
    },
    [currentComponent]
  )

  // use to set the default values (by 'default_answer_index') of the fields
  React.useEffect(
    () => {
      if (!isModerate) {
        if (
          reports?.reportStudents?.items?.length > 0 &&
          reports?.reportOptions?.length > 0
        ) {
          reports?.reportStudents?.items?.forEach(student => {
            reports?.reportOptions?.forEach(reportOption => {
              if (
                reportOption?.tag?.tag_type === 'progress' ||
                reportOption?.tag?.tag_type === 'tag'
              ) {
                reportOption?.tag?.tag_options?.forEach(option => {
                  const alreadyFilled =
                    fieldValues?.[`fieldsValues_${student.id}`]?.[
                      `${reportOption.tag.name}-${option.topic}`
                    ]?.answer
                  if (!isFirstStep) {
                    const isAbsent =
                      fieldValues?.[`fieldsValues_${student.id}`]?.[
                        fullNameField
                      ]?.answer === 'Ausente'
                    if (!isAbsent && !alreadyFilled) {
                      change(
                        `fieldsValues_${student.id}.${reportOption.tag.name}-${
                          option.topic
                        }`,
                        {
                          topic: option.topic,
                          tag_id: reportOption.tag.id,
                          answer:
                            option.possible_answers[
                              option.default_answer_index
                            ],
                          user_id: userId
                        }
                      )
                    }
                  } else if (
                    reportOption?.tag?.tag_order === 1 &&
                    !alreadyFilled
                  ) {
                    change(
                      `fieldsValues_${student.id}.${reportOption.tag.name}-${
                        option.topic
                      }`,
                      {
                        topic: option.topic,
                        tag_id: reportOption.tag.id,
                        answer:
                          option.possible_answers[option.default_answer_index],
                        user_id: userId
                      }
                    )
                  }
                })
              }
            })
          })
        }
      }
    },
    [isFirstStep, reportStudentsReady]
  )

  // realiza a atualização do conteúdo após o disparo do 'handleSelectionChanged', do preenchimento das respostas ou seleção do extraSection
  React.useEffect(
    () => {
      if (tagProps?.tag !== null && tagProps?.tag !== undefined) {
        const props = tagProps.tag
        if (props.tag_type === 'tag') {
          setTagTypeContent(
            <div key={'Tag'}>
              <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table} aria-label='customized table'>
                  <TableHead>
                    <TableRow className={classes.rowHead}>
                      <StyledTableCell style={{ width: '60%' }}>
                        Aluno
                      </StyledTableCell>
                      {props.tag_options.map(option => {
                        return (
                          <StyledTableCell
                            key={option.topic}
                            style={{ width: 'auto' }}
                          >
                            {option.topic}
                          </StyledTableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports &&
                      reports?.reportStudents?.items?.map(student => {
                        const isAbsent =
                          fieldValues?.[`fieldsValues_${student.id}`]?.[
                            fullNameField
                          ]?.answer === 'Ausente'
                        return (
                          <StyledTableRow key={`${student.id}-row`}>
                            <StyledTableCell align={'left'}>
                              <UserAvatar
                                style={isAbsent ? { opacity: '0.4' } : {}}
                                userName={student?.child?.name}
                                avatarUrl={student?.child?.avatar_url}
                              />
                            </StyledTableCell>
                            {props.tag_options.map(option => {
                              const fieldValue =
                                fieldValues?.[`fieldsValues_${student.id}`]?.[
                                  `${props?.name}-${option.topic}`
                                ]?.answer || null
                              const disable =
                                option.topic !==
                                  frequencyTags['frequencyForSubjectTopic'] &&
                                option.topic !==
                                  frequencyTags['frequencyForAllTopic'] &&
                                isAbsent
                              return (
                                <StyledTableCell
                                  key={`${student.id}-${option.topic}`}
                                  style={isAbsent ? { opacity: '0.4' } : {}}
                                  align={'left'}
                                >
                                  {option.possible_answers.map(answer => {
                                    const radioButton = ({
                                      input: { value, onChange, ...restInput },
                                      meta: { touched, invalid, error, active },
                                      ...rest
                                    }) => {
                                      return (
                                        <FormControl
                                          fullWidth
                                          disabled={disable}
                                          component='fieldset'
                                        >
                                          <RadioGroup
                                            row
                                            aria-label={option.topic}
                                            name={`${props?.name}-${
                                              option.topic
                                            }`}
                                            onChange={event => {
                                              handleChange(
                                                event,
                                                props,
                                                option,
                                                false,
                                                student.id
                                              )
                                            }}
                                            value={fieldValue}
                                            {...rest}
                                          >
                                            <>
                                              <FormControlLabel
                                                value={answer}
                                                control={
                                                  <Radio color='success' />
                                                }
                                                label={answer}
                                              />
                                            </>
                                          </RadioGroup>
                                          {touched &&
                                            !active &&
                                            error && (
                                              <span className={classes.error}>
                                                {error}
                                              </span>
                                            )}
                                        </FormControl>
                                      )
                                    }
                                    return (
                                      <Field
                                        key={`${student.id}-${props?.name}-${
                                          option.topic
                                        }`}
                                        component={radioButton}
                                        name={`${props?.name}-${option.topic}`}
                                      />
                                    )
                                  })}
                                </StyledTableCell>
                              )
                            })}
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
        } else if (props.tag_type === 'text') {
          setTagTypeContent(
            <div key={'Text'}>
              {reports &&
                reports?.reportStudents?.items?.map(student => {
                  const isAbsent =
                    fieldValues?.[`fieldsValues_${student.id}`]?.[fullNameField]
                      ?.answer === 'Ausente'
                  return (
                    <>
                      <div
                        className={classes.fieldWithText}
                        key={`${student.id}-row`}
                      >
                        <UserAvatar
                          style={isAbsent ? { opacity: '0.4' } : {}}
                          userName={student?.child?.name}
                          avatarUrl={student?.child?.avatar_url}
                        />
                        {student?.status === 'waiting_moderation' && (
                          <StyledSVG
                            width={21}
                            height={21}
                            src={moderatingSVG}
                          />
                        )}
                      </div>
                      {props.tag_options.map((option, index) => {
                        const fieldValue =
                          fieldValues?.[`fieldsValues_${student.id}`]?.[
                            `${props?.name}-${option.topic}`
                          ]?.answer || null
                        return (
                          <div key={index}>
                            <div className={classes.inputsWrapper}>
                              <Field
                                component={TextArea}
                                rootClass={classes.textAreaRoot}
                                key={option.topic}
                                name={`${props?.name}-${option.topic}`}
                                placeholder={option.topic}
                                rows='7'
                                type='text'
                                characterCounter='308'
                                onChangeInput={event =>
                                  handleChange(
                                    event,
                                    props,
                                    option,
                                    false,
                                    student.id
                                  )
                                }
                                defaultValue={fieldValue}
                                touch={touch}
                              />
                              <Field
                                key={`${student.id}-${option.id}`}
                                component={DropzoneInput}
                                maxFiles={100}
                                maxSize={524288000}
                                type='file'
                                onChangeInput={event => {
                                  handleInputFiles(event, student.id, props.id)
                                }}
                                dropzoneRootStyle={classes.dropzoneRootStyle}
                                withoutPreview
                              />
                            </div>
                            <ShowFiles
                              key={`${student.id}-${option.id}`}
                              allFiles={[
                                ...(fieldValues?.[`fieldsValues_${student.id}`]
                                  ?.files?.length > 0
                                  ? fieldValues?.[
                                      `fieldsValues_${student.id}`
                                    ]?.files
                                      .filter(item => +item.tagId === +props.id)
                                      .map(item => {
                                        return item.files
                                      })
                                      .flat()
                                  : []),
                                ...(fieldValues?.[`fieldsValues_${student.id}`]
                                  ?.reportFiles?.length > 0
                                  ? fieldValues?.[
                                      `fieldsValues_${student.id}`
                                    ]?.reportFiles.filter(
                                      item => +item.tag_id === +props.id
                                    )
                                  : [])
                              ]}
                              handleDeleteFilesInstance={
                                handleDeleteFilesInstance
                              }
                              handleDeleteNames={(filename, isFileInstance) => {
                                handleDeleteNames(
                                  filename,
                                  student.id,
                                  isFileInstance,
                                  props.id
                                )
                              }}
                            />
                          </div>
                        )
                      })}
                    </>
                  )
                })}
            </div>
          )
        } else if (props.tag_type === 'progress') {
          setTagTypeContent(
            <div key={'Progress'}>
              <h1>Alunos</h1>
              {reports &&
                reports?.reportStudents?.items.map(student => {
                  const isAbsent =
                    fieldValues?.[`fieldsValues_${student.id}`]?.[fullNameField]
                      ?.answer === 'Ausente'
                  return (
                    <div key={student.id} className={classes.progressContainer}>
                      <UserAvatar
                        style={isAbsent ? { opacity: '0.4' } : {}}
                        userName={student?.child?.name}
                        avatarUrl={student?.child?.avatar_url}
                      />
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {props.tag_options.map(option => {
                          const fieldValue =
                            fieldValues?.[`fieldsValues_${student.id}`]?.[
                              `${props?.name}-${option.topic}`
                            ]?.answer || null
                          const disable =
                            option.topic !==
                              frequencyTags['frequencyForSubjectTopic'] &&
                            option.topic !==
                              frequencyTags['frequencyForAllTopic'] &&
                            isAbsent
                          return (
                            <div
                              key={`${student.id}-${props?.name}-${
                                option.topic
                              }`}
                              style={{ marginRight: '8px' }}
                            >
                              <span className={classes.subLabel}>
                                {option.topic}
                              </span>
                              <Field
                                component={ReactSelect}
                                id={option.topic}
                                name={`${props?.name}-${option.topic}`}
                                placeholder='Selecione...'
                                handleChange={(event, input) =>
                                  handleChangeSelect(
                                    event,
                                    input,
                                    option,
                                    student.id
                                  )
                                }
                                defaultValue={fieldValue}
                                disabled={disable}
                                touch={touch}
                                options={option.possible_answers.map(
                                  answer => ({
                                    label: answer,
                                    value: answer
                                  })
                                )}
                                noUpdateOnBlur
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
            </div>
          )
        } else if (props.tag_type === 'subject') {
          const isForParents = props?.tag_order === 18
          if (isForParents) {
            const tagOption = props?.tag_options[0]
            const fieldValue =
              fieldValues?.[
                `fieldsValues_${reports?.reportStudents.items[0]?.id}`
              ]?.['Recado para turma-Recado aos responsáveis']
            setTagTypeContent(
              <div style={{ marginTop: '30px' }}>
                <h2>Adicione um recado aos responsáveis.</h2>
                <label htmlFor='message_classroom_due_date'>
                  <div className={classes.subjectContainerToParent}>
                    <div>
                      <span>Estabeleça uma data: </span>
                      <Field
                        component={DatePickerInput}
                        key={'message_classroom_due_date'}
                        placeholder='dd /mm /aaaa'
                        id='message_classroom_due_date'
                        name={`fieldsDates.${props?.name}-${
                          tagOption?.topic
                        }-due_date`}
                        defaultValue={fieldValue?.due_date}
                      />
                    </div>
                    {reports?.reportStudents.items[0]?.status ===
                      'waiting_moderation' &&
                      fieldValue?.answer && (
                        <StyledSVG width={21} height={21} src={moderatingSVG} />
                      )}
                  </div>
                </label>
                <div className={classes.inputsWrapper}>
                  <Field
                    component={TextArea}
                    key={tagOption?.topic}
                    rootClass={classes.textAreaRoot}
                    name={`${props?.name}-${tagOption?.topic}`}
                    placeholder={tagOption?.topic}
                    rows='7'
                    type='text'
                    characterCounter='308'
                    defaultValue={fieldValue?.answer}
                    onChangeInput={event =>
                      handleChange(event, props, tagOption, true)
                    }
                    touch={touch}
                  />
                  <Field
                    key={`${tagOption.id}`}
                    component={DropzoneInput}
                    maxFiles={100}
                    maxSize={524288000}
                    type='file'
                    dropzoneRootStyle={classes.dropzoneRootStyle}
                    onChangeInput={event => {
                      handleInputFiles(event, null, props.id, true)
                    }}
                    withoutPreview
                  />
                </div>
                <ShowFiles
                  key={`${tagOption.id}`}
                  allFiles={[
                    ...(fieldValues?.filesForAll?.files || []),
                    ...filesForAll
                  ]}
                  handleDeleteFilesInstance={handleDeleteFilesInstance}
                  handleDeleteNames={(filename, isFileInstance) => {
                    handleDeleteNames(
                      filename,
                      null,
                      isFileInstance,
                      props.id,
                      true
                    )
                  }}
                />
              </div>
            )
          } else {
            setTagTypeContent(
              <div style={{ marginTop: '30px' }}>
                {
                  <>
                    {props.tag_options.map(option => {
                      const fieldValue =
                        fieldValues?.[
                          `fieldsValues_${
                            reports?.reportStudents?.items?.[0]?.id
                          }`
                        ]?.[`${props?.name}-${option.topic}`] || null
                      return (
                        <div key={option.topic} className={classes.optionTopic}>
                          <div
                            className={classes.fieldWithText}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <UserAvatar
                              userName={option.topic}
                              avatarUrl={activitySVG}
                            />
                            <div className={classes.subjectContainer}>
                              <span>Data de envio:</span>
                              <Field
                                component={DatePickerInput}
                                key={`${props?.name}-${option.topic}-due_date`}
                                placeholder='dd /mm /aaaa'
                                id='due_date'
                                name={`fieldsDates.${props?.name}-${
                                  option.topic
                                }-due_date`}
                                defaultValue={fieldValue?.due_date}
                              />
                              {reports?.reportStudents.items[0]?.status ===
                                'waiting_moderation' &&
                                fieldValue?.answer && (
                                  <StyledSVG
                                    width={21}
                                    height={21}
                                    src={moderatingSVG}
                                  />
                                )}
                            </div>
                          </div>
                          <Field
                            component={TextArea}
                            key={`${props?.name}-${option?.topic}`}
                            name={`${props?.name}-${option?.topic}`}
                            placeholder={option.topic}
                            rows='7'
                            type='text'
                            characterCounter='308'
                            defaultValue={fieldValue?.answer}
                            onChangeInput={event =>
                              handleChange(event, props, option, true)
                            }
                            touch={touch}
                          />
                        </div>
                      )
                    })}
                  </>
                }
              </div>
            )
          }
        } else if (props.tag_type === 'multiple_choice') {
          setTagTypeContent(
            <div className={classes.tableRoot}>
              <div className={`${classes.tableRow} ${classes.tableHeader}`}>
                <div
                  className={`${classes.tableCell} ${classes.tableHead}`}
                  style={{ flex: 1 }}
                >
                  Aluno
                </div>
                {props.tag_options.map(option => (
                  <div
                    key={option.topic}
                    className={`${classes.tableCell} ${classes.tableHead}`}
                    style={{ flex: 2 }}
                  >
                    {option.topic}
                  </div>
                ))}
              </div>
              {reports?.reportStudents?.items?.map(student => {
                const isAbsent =
                  fieldValues?.[`fieldsValues_${student.id}`]?.[fullNameField]
                    ?.answer === 'Ausente'
                return (
                  <div key={`${student.id}-row`} className={classes.tableRow}>
                    <div className={classes.tableCell} style={{ flex: 1 }}>
                      <UserAvatar
                        style={isAbsent ? { opacity: '0.4' } : {}}
                        userName={student?.child?.name}
                        className={classes.multipleChoiceAvatar}
                        avatarUrl={student?.child?.avatar_url}
                      />
                    </div>
                    <div className={classes.tableCell} style={{ flex: 2 }}>
                      {props.tag_options.map((option, indx) => {
                        const fieldValue =
                          fieldValues?.[`fieldsValues_${student?.id}`]?.[
                            `${props?.name}-${option.topic}`
                          ] || null
                        const defaultValue =
                          isAbsent || fieldValue?.multiple_answers?.length === 0
                            ? ''
                            : fieldValue?.answer
                        return (
                          <div key={indx} className={classes.inputsWrapper}>
                            <div
                              key={option.topic}
                              className={classes.multipleChoiceArea}
                              style={isAbsent ? { opacity: '0.4' } : {}}
                            >
                              <div className={classes.sendToAllSchool}>
                                <span className={classes.selectSymptoms}>
                                  Selecione os sintomas
                                  {student?.status === 'waiting_moderation' &&
                                    fieldValue?.answer && (
                                      <StyledSVG
                                        width={21}
                                        height={21}
                                        src={moderatingSVG}
                                      />
                                    )}
                                </span>
                                {option.possible_answers.map(answer => {
                                  return (
                                    <div
                                      key={answer}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                      }}
                                    >
                                      <CheckboxInput
                                        disabled={isAbsent}
                                        name={answer}
                                        checked={fieldValue?.multiple_answers?.includes(
                                          answer
                                        )}
                                        handleCheck={event => {
                                          handleCheck(
                                            event,
                                            props,
                                            option,
                                            student.id
                                          )
                                        }}
                                      />
                                      <p>{answer}</p>
                                    </div>
                                  )
                                })}
                              </div>
                              <TextField
                                id='standard-basic'
                                label='Descreva os sintomas'
                                variant='standard'
                                className={classes.textField}
                                inputProps={{ maxLength: 300 }}
                                disabled={
                                  isAbsent ||
                                  fieldValue?.multiple_answers?.length === 0
                                }
                                name={`${props?.name}-${option?.topic}`}
                                multiline
                                value={defaultValue}
                                defaultValue={
                                  isAbsent ||
                                  fieldValue?.multiple_answers?.length === 0
                                    ? ''
                                    : fieldValue?.answer
                                }
                                onChange={event => {
                                  handleChange(
                                    event,
                                    props,
                                    option,
                                    false,
                                    student.id
                                  )
                                }}
                              />
                            </div>
                            <div
                              className={classes.multipleChoiceInputsWrapper}
                            >
                              <Field
                                key={`${student.id}-${option.id}`}
                                component={DropzoneInput}
                                maxFiles={100}
                                maxSize={524288000}
                                type='file'
                                dropzoneRootStyle={
                                  classes.multipleChoiceDropzone
                                }
                                onChangeInput={event => {
                                  handleInputFiles(event, student.id, props.id)
                                }}
                                withoutPreview
                              />
                              <ShowFiles
                                key={`${student.id}-${option.id}`}
                                allFiles={[
                                  ...(fieldValues?.[
                                    `fieldsValues_${student.id}`
                                  ]?.files?.length > 0
                                    ? fieldValues?.[
                                        `fieldsValues_${student.id}`
                                      ]?.files
                                        .filter(
                                          item => +item.tagId === +props.id
                                        )
                                        .map(item => {
                                          return item.files
                                        })
                                        .flat()
                                    : []),
                                  ...(fieldValues?.[
                                    `fieldsValues_${student.id}`
                                  ]?.reportFiles?.length > 0
                                    ? fieldValues?.[
                                        `fieldsValues_${student.id}`
                                      ]?.reportFiles.filter(
                                        item => +item.tag_id === +props.id
                                      )
                                    : [])
                                ]}
                                handleDeleteFilesInstance={
                                  handleDeleteFilesInstance
                                }
                                handleDeleteNames={(
                                  filename,
                                  isFileInstance
                                ) => {
                                  handleDeleteNames(
                                    filename,
                                    student.id,
                                    isFileInstance,
                                    props.id
                                  )
                                }}
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        } else if (props.tag_type === 'subjects_frequency') {
          setTagTypeContent(
            <div key={'subjects_frequency'}>
              <div className={classes.searchField}>
                <label>Disciplinas</label>
                <Field
                  component={AsyncSelect}
                  defaultOptions={false}
                  id='subjectValue'
                  name='subjectValue'
                  placeholder='Selecione uma disciplina.'
                  handleAsyncChange={event => change('subjectValue', event)}
                  defaultValue={fieldValues?.subjectValue || null}
                  searchParam='q[name_cont]'
                  request={{
                    path: 'subject',
                    params: {
                      'q[teacher_subjects_school_teacher_user_id_eq]': isAdmin
                        ? undefined
                        : userId
                    }
                  }}
                  touch={touch}
                />
              </div>
              <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table} aria-label='customized table'>
                  <TableHead>
                    <TableRow className={classes.rowHead}>
                      <StyledTableCell style={{ width: '60%' }}>
                        Aluno
                      </StyledTableCell>
                      {props.tag_options.map(option => {
                        return (
                          <StyledTableCell
                            key={option.topic}
                            style={{ width: 'auto' }}
                          >
                            {option.topic}
                          </StyledTableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports &&
                      reports?.reportStudents?.items?.map(student => {
                        return (
                          <StyledTableRow key={`${student.id}-row`}>
                            <StyledTableCell align={'left'}>
                              <UserAvatar
                                userName={student?.child?.name}
                                avatarUrl={student?.child?.avatar_url}
                              />
                            </StyledTableCell>
                            {props.tag_options.map(option => {
                              const fieldValue =
                                fieldValues?.[`fieldsValues_${student.id}`]?.[
                                  `${props?.name}-${option.topic}-${
                                    fieldValues?.subjectValue?.label
                                  }`
                                ]?.answer || null
                              return (
                                <StyledTableCell
                                  key={`${student.id}-${option.topic}`}
                                  align={'left'}
                                >
                                  {option.possible_answers.map(answer => {
                                    const radioButton = ({
                                      input: { value, onChange, ...restInput },
                                      meta: { touched, invalid, error, active },
                                      ...rest
                                    }) => {
                                      return (
                                        <FormControl
                                          fullWidth
                                          component='fieldset'
                                        >
                                          <RadioGroup
                                            row
                                            aria-label={option.topic}
                                            name={`${props?.name}-${
                                              option.topic
                                            }-${
                                              fieldValues?.subjectValue?.label
                                            }`}
                                            onChange={event => {
                                              if (
                                                fieldValues?.subjectValue?.value
                                              ) {
                                                handleChange(
                                                  event,
                                                  props,
                                                  option,
                                                  false,
                                                  student.id
                                                )
                                              } else {
                                                alert(
                                                  'Selecione uma disciplina para preencher a frequência.',
                                                  'warning'
                                                )
                                              }
                                            }}
                                            value={fieldValue}
                                            {...rest}
                                          >
                                            <>
                                              <FormControlLabel
                                                value={answer}
                                                control={
                                                  <Radio color='success' />
                                                }
                                                label={answer}
                                              />
                                            </>
                                          </RadioGroup>
                                          {touched &&
                                            !active &&
                                            error && (
                                              <span className={classes.error}>
                                                {error}
                                              </span>
                                            )}
                                        </FormControl>
                                      )
                                    }
                                    return (
                                      <Field
                                        key={`${student.id}-${props?.name}-${
                                          option.topic
                                        }`}
                                        component={radioButton}
                                        name={`${props?.name}-${option.topic}`}
                                      />
                                    )
                                  })}
                                </StyledTableCell>
                              )
                            })}
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
        }
      }
    },
    [tagProps?.tag, fieldValues]
  )

  const handleCheck = (event, tagProps, tagOptionProps, studentId) => {
    const { checked, name } = event.target
    const answers =
      fieldValues?.[`fieldsValues_${studentId}`]?.[
        `${tagProps.name}-${tagOptionProps.topic}`
      ]?.multiple_answers || []
    const changeReduxForm = arrayOfAnswers => {
      change(
        `fieldsValues_${studentId}.${`${tagProps.name}-${
          tagOptionProps.topic
        }`}`,
        {
          topic: tagOptionProps.topic,
          tag_id: tagProps.id,
          user_id: userId,
          multiple_answers: [...arrayOfAnswers]
        }
      )
    }
    if (checked) {
      changeReduxForm([...answers, name])
    } else {
      const answersUnchecked = answers.filter(answer => answer !== name)
      changeReduxForm(answersUnchecked)
    }
  }

  const handleInputFiles = (event, studentId, tagId, isForAllStudents) => {
    if (isForAllStudents) {
      const previousFiles = fieldValues?.filesForAll
      const newState = {
        ...(previousFiles || []),
        files: event,
        tagId
      }
      change('filesForAll', newState)
    } else {
      const previousFiles = fieldValues?.[`fieldsValues_${studentId}`]?.files
      const newState = [
        ...(previousFiles || []),
        { files: event, tagId }
      ].reduce((acc, current) => {
        const existing = acc.find(item => item.tagId === current.tagId)
        if (existing) {
          existing.files = [...existing.files, ...current.files]
        } else {
          acc.push(current)
        }
        return acc
      }, [])
      change(`fieldsValues_${studentId}.files`, newState)
    }
  }

  const handleChange = (
    event,
    tagProps,
    tagOptionProps,
    forEachStudent,
    studentId
  ) => {
    if (forEachStudent) {
      reports &&
        reports?.reportStudents?.items.map(student => {
          change(`fieldsValues_${student.id}.${event.target.name}`, {
            topic: tagOptionProps.topic,
            tag_id: tagProps.id,
            answer: event.target.value,
            user_id: userId
          })
        })
    } else {
      let answerObject = {
        topic: tagOptionProps.topic,
        tag_id: tagProps.id,
        answer: event.target.value,
        user_id: userId
      }
      if (tagProps.name === frequencyTags['frequencyForSubjectName']) {
        Object.assign(answerObject, {
          subject_id: fieldValues?.subjectValue?.value,
          user_id: userId
        })
      }
      change(`fieldsValues_${studentId}.${event.target.name}`, answerObject)
      if (tagProps.name === 'Enfermaria') {
        const multipleChoiceObject =
          fieldValues?.[`fieldsValues_${studentId}`]?.[
            `${tagProps.name}-${tagOptionProps.topic}`
          ]
        if (multipleChoiceObject?.multiple_answers?.length > 0) {
          change(`fieldsValues_${studentId}.${event.target.name}`, {
            ...multipleChoiceObject,
            answer: event.target.value
          })
        } else {
          change(`fieldsValues_${studentId}.${event.target.name}`, {
            ...multipleChoiceObject,
            answer: ''
          })
        }
      }
    }
  }
  const handleChangeSelect = (event, input, tagOptionProps, studentId) => {
    change(`fieldsValues_${studentId}.${[input.name]}`, {
      topic: tagOptionProps.topic,
      tag_id: tagOptionProps.tag_id,
      answer: event.value,
      user_id: userId
    })
  }

  const handleSelectionChanged = props => {
    setCurrentTag(props.tag.tag_order)
    setTagProps(props)
  }

  const handleDisabledButton = () => {
    if (isModerate) {
      return false
    } else {
      if (isFirstStep && canSkipFromFirstTag !== true) {
        return true
      } else if (
        currentComponent?.tag?.tag_type === 'progress' &&
        canSkipToNextTag !== true
      ) {
        return true
      } else if (
        !isFirstStep &&
        currentComponent?.tag?.tag_type === 'tag' &&
        canSkipToNextTag !== true
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const handleDeleteFilesInstance = fileId => {
    let newState = []
    filesToDelete?.length === 0
      ? newState.push(fileId)
      : (newState = [...(filesToDelete || []), fileId])
    change('filesToDelete', newState)
  }

  const handleDeleteNames = (
    name,
    studentId,
    isFileInstance,
    tagId,
    forAllStudents
  ) => {
    if (name) {
      if (isFileInstance) {
        if (forAllStudents) {
          setFilesForAll(prev => {
            return prev.filter(reportFile => {
              return reportFile.filename !== name
            })
          })
        } else {
          const newStateFiltered = fieldValues?.[
            `fieldsValues_${studentId}`
          ]?.reportFiles?.filter(item => item.filename !== name)
          change(`fieldsValues_${studentId}.reportFiles`, newStateFiltered)
        }
      } else {
        if (forAllStudents) {
          const newStateFiltered = fieldValues?.filesForAll?.files.filter(
            reportFile => {
              return reportFile.name !== name
            }
          )
          change('filesForAll', {
            tagId: fieldValues?.filesForAll.tagId,
            files: newStateFiltered
          })
        } else {
          const newStateFiltered = fieldValues?.[
            `fieldsValues_${studentId}`
          ]?.files
            .filter(item => +item.tagId === +tagId)?.[0]
            ?.files.filter(reportFile => {
              return reportFile.name !== name
            })

          const newState = fieldValues?.[
            `fieldsValues_${studentId}`
          ]?.files.filter(item => +item.tagId !== +tagId)

          if (Array.isArray(newState)) {
            newState.push({ tagId, files: newStateFiltered })
          }
          change(`fieldsValues_${studentId}.files`, newState)
        }
      }
    }
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {formComponents.length > 0 &&
            formComponents.map(reportQuestion => {
              return (
                <div
                  key={reportQuestion.tag.id}
                  style={{ marginRight: '5px', marginBottom: '10px' }}
                >
                  <Chip
                    checked={currentTag === reportQuestion.tag.tag_order}
                    label={reportQuestion.tag.name}
                    name={reportQuestion.tag.id}
                  />
                </div>
              )
            })}
        </div>
        {formComponents.length > 0 ? (
          tagTypeContent
        ) : (
          <NoAnswersToModerateDiv />
        )}
        <div className={classes.navegationSection}>
          <Button
            type='button'
            className={classes.exitBtn}
            onClick={() => setConfirmExitForm(prev => !prev)}
          >
            Sair
          </Button>
          <div id='navigation'>
            {!(formComponents.length === 0 && isModerate) && (
              <div style={{ display: 'flex', gap: 10 }}>
                {!isFirstStep && (
                  <Button
                    variant='outlined'
                    className={classes.backBtn}
                    onclick={() => changeStep(currentStep - 1)}
                  >
                    Voltar
                  </Button>
                )}
                {!isLastStep && (
                  <Button
                    type='button'
                    className={classes.continueBtn}
                    disabled={handleDisabledButton()}
                    onClick={() => changeStep(currentStep + 1)}
                  >
                    Avançar
                  </Button>
                )}
              </div>
            )}
            {(isLastStep || (formComponents.length === 0 && isModerate)) && (
              // <Button className={classes.sendBtn} onClick={setFileSection}>
              //   Avançar
              // </Button>
              <Button
                className={classes.sendBtn}
                onClick={handleToggle}
                disabled={handleDisabledButton()}
                type='submit'
              >
                {isModerate ? 'Moderar' : 'Enviar'}
              </Button>
              // <Button
              //   className={classes.sendBtn}
              //   onClick={handleToggle}
              //   disabled={handleDisabledButton()}
              //   type='submit'
              // >
              //   {isModerate ? 'Moderar' : 'Enviar'}
              // </Button>
            )}
            {/* {extraSection && (
              <Button
                className={classes.sendBtn}
                onClick={handleToggle}
                disabled={handleDisabledButton()}
                type='submit'
              >
                {isModerate ? 'Moderar' : 'Enviar'}
              </Button>
            )} */}
          </div>
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color='secondary' />
          </Backdrop>
        </div>
        <Modal
          open={confirmExitForm}
          handleClose={() => setConfirmExitForm(prev => !prev)}
        >
          <div>
            <h1>Atenção!</h1>
            <h2>
              {`Sair antes de finalizar o envio do diário fará com que ${
                isModerate
                  ? 'a moderação não seja concluída.'
                  : 'as respostas sejam perdidas.'
              }`}
            </h2>
          </div>
          <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
            <Button
              className={classes.exitBtn}
              variant='contained'
              onClick={() => history.push(`/reports/${reportId}/classroom`)}
            >
              Sair
            </Button>
            <Button
              variant='outlined'
              className={classes.cancelBtn}
              onClick={() => {
                setConfirmExitForm(prev => !prev)
              }}
            >
              Cancelar
            </Button>
          </div>
        </Modal>
        <Modal
          open={confirmStatusModal}
          handleClose={() => {
            history.push(`/reports/${reportId}/classroom`)
          }}
        >
          <div className={classes.confirmModal}>
            <StyledSVG
              src={MessageConfirmationSVG}
              width={matches ? 500 : 250}
              height={matches ? 350 : 200}
            />
            <h1>{`Diários ${
              isModerate ? 'moderados' : 'enviados'
            } com sucesso!`}</h1>
          </div>
          <div>
            <Button
              className={classes.continueBtn}
              onClick={() => {
                history.push(`/reports/${reportId}/classroom`)
              }}
            >
              Continuar
            </Button>
          </div>
        </Modal>
      </Form>
    </div>
  )
}

const mapStateToProps = state => {
  const { reports, auth, alert } = state
  const selector = formValueSelector('reportsForAllStudentsForm')
  let studentsAnswers = []
  let filesForAllStudents = []
  reports.reportStudents?.items &&
    reports.reportStudents?.items.map(item => {
      let answersForSubjectFrequency = []
      const answersWithoutSubjcFrequency = item?.answers
        ?.filter(item => {
          return item.current_answer === true
        })
        // .filter(item => {
        //   // text or subject tag for parents
        //   return item.tag.tag_type !== 'text' && item.tag.tag_order !== 18
        // })
        .filter(item => {
          if (item?.topic !== frequencyTags['frequencyForSubjectTopic']) {
            return true
          } else {
            answersForSubjectFrequency.push(item)
            return false
          }
        })
      const objectWithSubjcFrequency = answersForSubjectFrequency
        .map(subjc => {
          const frequencyAnswers = convertArrayToObject(
            answersForSubjectFrequency,
            'topic',
            true
          )
          return frequencyAnswers
        })
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
      const objectWithAnswers =
        answersWithoutSubjcFrequency &&
        convertArrayToObject(answersWithoutSubjcFrequency, 'topic')
      const reportFilesFiltered = item?.report_files?.filter(rptf => {
        if (rptf?.tag?.tag_order === 18) {
          filesForAllStudents.push(rptf)
        }
        return rptf?.tag?.tag_type !== 'subject'
      })
      studentsAnswers.push({
        [`fieldsValues_${item.id}`]: {
          ...objectWithAnswers,
          ...objectWithSubjcFrequency,
          reportFiles: reportFilesFiltered
        }
      })
    })

  const studentsAnswersObject = studentsAnswers.reduce((acc, curr) => {
    const key = Object.keys(curr)[0] // obter a chave do objeto atual
    return { ...acc, [key]: curr[key] } // adicionar a chave e valor ao acumulador
  }, {})

  return {
    initialValues: studentsAnswersObject,
    filesForAllStudents: Array.from(
      new Map(filesForAllStudents.map(item => [item.id, item])).values()
    ),
    userOccupations: state?.auth?.currentOccupation,
    filesToDelete: selector(state, 'filesToDelete'),
    fieldValues: getFormValues('reportsForAllStudentsForm')(state),
    isFetching: reports.isFetching,
    isSuccess: reports.isSuccess,
    userId: auth?.user?.data?.id,
    submitSuccess: alert?.alertMessage?.severity === 'success'
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) =>
    dispatch(change('reportsForAllStudentsForm', field, data)),
  editReportStudent: data => dispatch(editReportStudent(data)),
  getTagsRSPA: data => dispatch(getTagsRSPA.request(data)),
  editReportStudentByClassroom: data =>
    dispatch(editReportStudentByClassroom(data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'reportsForAllStudentsForm',
    touchOnChange: true,
    enableReinitialize: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      const { filesToDelete, filesForAll } = values
      delete values.filesToDelete

      const formAnswers = Object.entries(values).filter(
        ([key, value]) =>
          key.split('_')[0] ? key.split('_')[0] === 'fieldsValues' : undefined
      )

      const studentFiles = []
      const studentIds = []
      formAnswers.map((repStudent, idx) => {
        const reportStudentId = repStudent[0].split('_')[1]
        studentIds.push(reportStudentId)
        const reportStudentAnswers = repStudent[1]
        const { files } = reportStudentAnswers
        files?.length > 0 &&
          studentFiles.push({ [reportStudentId]: { ...files } })
      })

      props.editReportStudentByClassroom({
        values,
        reports: props.reports,
        userId: props.userId,
        formAnswers,
        studentFiles: studentFiles.map(item => Object.entries(item)),
        studentIds: studentIds,
        filesToDelete,
        filesForAll,
        reportId: props.reportId
      })
    }
  })(ReportStudentByClassroomForm)
)
